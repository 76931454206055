import { useLocation } from '@reach/router'
import { useStaticQuery, graphql } from 'gatsby'
import { getImage, getSrc } from 'gatsby-plugin-image'
import React from 'react'
import { Helmet } from 'react-helmet'

const SEO = ({ title, meta, lang }) => {
  // handle empty meta (e.g. for jobs)
  meta = meta || []

  // collect defaults
  const { seo } = useStaticQuery(graphql/* GraphQL */ `
    query seoQuery {
      seo: markdownRemark(fileAbsolutePath: { regex: "/partials/seo.md/" }) {
        frontmatter {
          default_seo {
            page_title
            page_description
            twitter
            social_media_image {
              childImageSharp {
                gatsbyImageData(width: 1500, formats: [PNG], placeholder: BLURRED, layout: FIXED)
              }
            }
          }
        }
      }
    }
  `)
  const defaults = seo.frontmatter.default_seo

  // prep to store extra meta info
  const extraMeta = []

  // set up canonical address
  const canonical = meta.canonical ? meta.canonical : useLocation().href

  // set up robots meta
  const robots = []
  meta.nofollow && robots.push('nofollow')
  meta.noindex && robots.push('noindex')
  if (robots.length) {
    extraMeta.push({ name: 'robots', content: robots.join(', ') })
  }

  const pageTitle = title || defaults.page_title
  const pageDesc = meta.description || defaults.page_description
  const pageImage =
    meta.og_image && meta.og_image.relativePath !== 'dummy.png'
      ? getSrc(meta.og_image)
      : getSrc(defaults.social_media_image)

  // set up social media images
  if (pageImage) {
    const base = process.env.GATSBY_SITE_URL || 'https://focusedlabs.io'
    const src = pageImage
    extraMeta.push({ property: 'og:image', content: `${base}${src}` })
    extraMeta.push({ name: 'twitter:image', content: `${base}${src}` })
    extraMeta.push({ name: 'twitter:image:alt', content: pageTitle })
  }

  // return the seo stuff
  return (
    <Helmet
      htmlAttributes={{
        lang: lang || 'en',
      }}
      title={pageTitle}
      meta={[
        {
          name: `description`,
          content: pageDesc,
        },
        {
          property: `og:title`,
          content: pageTitle,
        },
        {
          property: `og:description`,
          content: pageDesc,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: canonical,
        },
        {
          property: `og:site_name`,
          content: defaults.page_title,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: defaults.twitter ? defaults.twitter : ``,
        },
        {
          name: `twitter:title`,
          content: pageTitle,
        },
        {
          name: `twitter:description`,
          content: pageDesc,
        },
      ].concat(extraMeta)}
    >
      <link rel="canonical" href={canonical} />
    </Helmet>
  )
}

export default SEO
